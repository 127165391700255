import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import Favicon from '../assets/img/favicon.ico';
import KetentuanLayanan from '../componentsAtomic/templates/V2/TermOfUse';
import useDetectId from '../hooks/useDetectId';
import NotFound from './404';

const Render = () => {
	const intl = useIntl();
	const { isId } = useDetectId();
	if (isId) return <NotFound />;

	return (
		<>
			<Helmet title={intl.formatMessage({ id: `term_of_use.meta.title` })}>
				<meta name="description" content={intl.formatMessage({ id: `term_of_use.meta.desc` })} />
				<meta name="author" content="Chatbiz" />
				<meta name="theme-color" content="#04589b" />
				<link rel="icon" href={Favicon} />
				<html lang="id" />

				<meta name="robots" content="index, follow" />
			</Helmet>
			<KetentuanLayanan />
		</>
	);
};

export default Render;

// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
// import 'typeface-montserrat';
// import 'bootstrap/dist/css/bootstrap.css';
// import '../assets/_homepage/sass/main.scss';
// import 'lazysizes';

// import Navbar from '../components/_homepage/navbar';
// import SideBar from '../components/_homepage/sidebar';
// import GeneralTXT from '../components/_homepage/GeneralTxt';
// import ModalRequest from '../components/_homepage/modalRequest';
// import Footer from '../components/_homepage/footer';
// import Favicon from '../assets/img/favicon.ico';

// const txtHtml = `
// <p><span>PENDAHULUAN</span></p>
// <p>Ketentuan Layanan penting untuk menetapkan apa yang dapat Anda harapkan dari Chatbiz.id saat Anda menggunakan&nbsp;layanan&nbsp;Chatbiz.id, dan apa yang Chatbiz.id harapkan dari Anda. Ketentuan Layanan ini memberitahu bagaimana cara kerja bisnis Chatbiz.id, hukum yang berlaku untuk perusahaan, dan&nbsp;hal-hal tertentu yang Chatbiz.id yakini benar.</p>
// <p></p>
// <p>Oleh karena itu, Persyaratan Layanan ini membantu menentukan hubungan Chatbiz.id dengan Anda saat Anda berinteraksi dengan layanan Chatbiz.id.</p>
// <p></p>
// <p>Penting bagi Anda untuk memahami persyaratan ini, karena dengan menggunakan layanan Chatbiz.id, Anda di anggap telah menyetujui semua komponen dari Ketentuan Layanan ini.</p>
// <p>&nbsp;</p>
// <p><span>HUBUNGAN ANDA DENGAN CHATBIZ.ID</span></p>
// <p>Persyaratan ini membantu menentukan hubungan antara Anda dan Chatbiz.id. Pada umumnya, jika Anda menggunakan layanan Chatbiz.id, maka Anda telah setuju untuk mengikuti persyaratan ini. Saat berbicara tentang "Chatbiz.id", yang dimaksud adalah semua layanan yang disediakan oleh Chatbiz.id.</p>
// <p>&nbsp;</p>
// <p><span>HAL-HAL YANG CHATBIZ.ID HARAPKAN DARI ANDA</span></p>
// <p>Layanan Chatbiz.id memungkinkan Anda berinteraksi dengan orang lain. Chatbiz.id ingin menjaga lingkungan yang saling menghormati bagi semua orang, yang artinya Anda harus mengikuti aturan perilaku dasar ini:</p>
// <ol>
// <li>Mematuhi hukum yang berlaku, termasuk kontrol ekspor, sanksi, dan hukum perdagangan.</li>
// <li>Menghormati hak orang lain.</li>
// <li>Tidak melecehkan atau membahayakan orang lain atau diri Anda sendiri, seperti menyesatkan, menipu, mencemarkan nama baik, menindas, melecehkan dalam bentuk apa pun.</li>
// <li>tidak menyalahgunakan, membahayakan, mengusik, atau mengganggu layanan</li>
// </ol>
// <p>&nbsp;</p>
// <p><span>TINDAKAN MELAWAN HUKUM</span></p>
// <p>Setiap tindakan melawan hukum yang Anda lakukan pada saat penggunaan layanan</p>
// <p>Chatbiz.id akan di proses sesuai dengan hukum yang berlaku di Indonesia. Chatbiz.id juga tidak bertanggungjawab atas segala tindakan yang dilakukan Anda selaku pengguna layanan Chatbiz.id terhadap pihak ke tiga.</p>
// <p></p>
// <p>Dengan menggunakan layanan Chatbiz.id, Anda telah memahami dan menyetujui Ketentuan Layanan ini dan setiap komponen yang tertulis sampai akhir</p>
// <p>&nbsp;</p>
// <p><span>KETENTUAN TERPISAH</span></p>
// <p>Ketentuan lainnya yang tidak tertulis dalam ketentuan ini dapat timbul dalam kontrak terpisah yang telah disetujui bersama saat akan menggunakan layanan Chatbiz.id</p>
// `;

// const KetentuanLayanan = () => {
// 	const [isSideBar, setSideBar] = useState(false);
// 	const [isModalDemo, setModalDemo] = useState(false);
// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, []);
// 	return (
// 		<>
// 			<Helmet title="Ketentuan Layanan | Chatbiz.id">
// 				<meta
// 					name="description"
// 					content="Ketentuan Layanan penting untuk menetapkan apa yang dapat Anda harapkan dari Chatbiz.id saat Anda menggunakan layanan Chatbiz.id, dan apa yang Chatbiz.id harapkan dari Anda. Ketentuan Layanan ini memberitahu bagaimana cara kerja bisnis Chatbiz.id."
// 				/>
// 				<meta name="author" content="Chatbiz" />
// 				<meta data-vue-tag="ssr" name="theme-color" content="#04589b" />
// 				<link rel="icon" href={Favicon} />
// 			</Helmet>
// 			<div id="HomePageChatBiz" className="contentHomePage">
// 				<SideBar isSideBar={isSideBar} setSideBar={setSideBar} setModalDemo={setModalDemo} />
// 				<Navbar setSideBar={setSideBar} setModalDemo={setModalDemo} />
// 				<GeneralTXT txtHtml={txtHtml} title="Ketentuan Layanan" modified="3 September 2020" />
// 				<Footer />
// 				{isModalDemo && <ModalRequest setModalDemo={setModalDemo} />}
// 			</div>
// 		</>
// 	);
// };

// export default KetentuanLayanan;
