/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

// import { Helmet } from 'react-helmet';
import 'lazysizes';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import '@fontsource/nunito';

import Menu from '../../../organisms/v2/Menu';
import GeneralTXT from '../../../organisms/GeneralTxt';
import Footer from '../../../organisms/Footer';
import ModalChatbiz from '../../../organisms/Modal/ModalChatbiz';
import withDefautlHoc from '../../../_hoc/global';

import withIntl from '../../../../helper/hoc/withIntl';

const PrivacyPolicy = ({ enPrefix }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const intl = useIntl();

	return (
		<>
			<Menu
				enPrefix={enPrefix}
				onDemoClick={() => {
					setModalOpen(true);
				}}
			/>
			<GeneralTXT
				title={intl.formatMessage({ id: `term_of_use.title` })}
				txtHtml={intl.formatMessage({ id: `term_of_use.text` })}
			/>
			<Footer enPrefix={enPrefix} />
			{modalOpen && (
				<ModalChatbiz
					isModalOpen={modalOpen}
					onClose={() => {
						setModalOpen(false);
					}}
					onSuccess={() => {
						setModalOpen(false);
					}}
				/>
			)}
		</>
	);
};

PrivacyPolicy.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default withIntl(withDefautlHoc(PrivacyPolicy));
